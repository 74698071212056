<template>
    <div>
        <Search />
        <div class="head">
            <div class="nav">
                <div @click="goBack">
                    <i style="font-size:20px;padding-top:2px" class="el-icon-arrow-left"></i>
                    返回
                </div>
            </div>
        </div>
        <div class="user-box">
            <div class="user-info">
                <div class="logo">
                    <img :src="$util.host + info.headPic" alt="">
                </div>
                <div>
                    <div class="user-name">{{info.name}}</div>
                    <div class="user-tags">
                        <div class="tag">
                            <i style="color:#e40012;font-size:24px;margin-right:10px" class="el-icon-user-solid"></i>
                            <span v-text="info.accountType == 1?'个人用户':'企业用户'"></span>
                        </div>
                        <div class="tag">
                            <img style="width:15px;height:19px;margin-right:10px" src="../../assets/images/serve/dw.png" alt="">
                            {{mapAll[info.province]}} {{mapAll[info.city]}}
                        </div>
                        <div class="tag">
                            <img style="width:18px;height:17px;margin-right:10px" src="../../assets/images/serve/hy.png" alt="">
                            {{industry[info.industry]}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="many">
                <div class="nav">
                    <div><span>{{needList.length}}</span> 需求</div>
                    <div><span>{{shopList.length}}</span> 店铺</div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="main-box">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="需求" name="first">
                        <div class="litle-need">
                            <div class="litle-cell" v-for="item in needList" :key="item.id" @click="goDemand(item.id)">
                                <div class="litle-title">{{item.name}}</div>
                                <div class="tag-box">
                                    <div class="tag" v-text="item.sendUserType == 1?'个人用户':'企业用户'"></div>
                                    <div class="tag">{{industry[item.sendIndustry]}}</div>
                                    <div class="tag">{{mapAll[item.sendProvince]}}{{mapAll[item.sendCity]}}</div>
                                </div>
                                <div class="litle-money">预算 ￥ {{item.sendMoneyBudget}}</div>
                            </div>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane label="店铺" name="second">
                        <div class="zan">
                            <img src="../../assets/images/serve/zan.png" alt="">
                            优质店铺
                        </div>
                        <div class="nice-shop">
                            <div class="good" v-for="item in shopList" :key="item.id" @click="goDetail(item.id,shop.name)">
                                <img :src="$util.host + item.cover" alt="">
                                <div>{{item.name}}</div>
                                <span>预算￥{{item.budget}}</span>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="pagination" v-show="current == 0">
                <el-pagination background layout="prev, pager, next" :current-page="needPage" :page-size="needPage.pageSize" @current-change="changePage" :total="needPage.totalRows">
                </el-pagination>
                <div class="total">共{{needPage.totalRows}}件/{{needPage.totalPage}}页</div>
                <div class="jump">
                    跳转到
                    <input type="number" v-model="needNum">
                    页
                </div>
                <el-button style="background:#2EA7E0;color:#fff;width:108px;height:50px;font-size:20px" @click="goNeedPage">前往</el-button>
            </div>
            <div class="pagination" v-show="current == 1">
                <el-pagination background layout="prev, pager, next" :current-page="goodPage" :page-size="goodsPage.pageSize" @current-change="changeGoodsPage" :total="goodsPage.totalRows">
                </el-pagination>
                <div class="total">共{{goodsPage.totalRows}}件/{{goodsPage.totalPage}}页</div>
                <div class="jump">
                    跳转到
                    <input type="number" v-model="goodsNum">
                    页
                </div>
                <el-button style="background:#2EA7E0;color:#fff;width:108px;height:50px;font-size:20px" @click="goGoodsPage">前往</el-button>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Search from "@/components/search.vue";
import Footer from "../../components/footer.vue";
export default {
    components: { Search, Footer },
    data() {
        return {
            current: 0, //当亲下标
            activeName: 'first',
            info: {}, //当前人的信息
            mapAll: {}, //所有地址
            needList: [], //需求列表
            needPage: {}, //需求分页数据
            needNum: '',
            industry: {
                'A': '农、林、牧、渔业',
                'B': '采矿业,',
                'C': '制造业',
                'D': '电力、热力、燃气及水生产和供应业',
                'E': '建筑业',
                'F': '批发和零售业',
                'G': '交通运输、仓储和邮政业',
                'H': '住宿和餐饮业',
                'I': '信息传输、软件和信息技术服务业',
                'J': '金融业',
                'K': '房地产业',
                'L': '租赁和商务服务业',
                'M': '科学研究和技术服务业',
                'N': '水利、环境和公共设施管理业',
                'O': '居民服务、修理和其他服务业',
                'P': '教育',
                'Q': '卫生和社会工作',
                'R': '文化、体育和娱乐业',
                'S': '公共管理、社会保障和社会组织',
                'T': '国际组织',
            }, //行业.
            needPage: 1, //需求页码
            shopList: [], //商品列表
            goodsPage: {}, //商品分页数据
            goodsNum: '',
            goodPage: 1, //商品页码
        };
    },
    mounted() {
        this.getAllMap()
        this.getInfo()
        this.allNeed()
        this.allShop()
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        getInfo() {
            this.$util.post('/user/otherInfo', {
                uid: this.$route.query.uid
            }).then(res => {
                this.info = res.data[0]
            })
        },
        // 获取需求
        allNeed() {
            this.$util.post('/user/otherOrder', {
                uid: this.$route.query.uid,
                pageNo: this.needPage
            }).then(res => {
                this.needList = res.data
                this.needPage = res.pager
            })
        },
        // 获取商品
        allShop() {
            this.$util.post('/user/otherShopGoods', {
                uid: this.$route.query.uid,
                pageNo: this.goodPage
            }).then(res => {
                this.shopList = res.data
                this.goodsPage = res.pager
            })
        },
        // 获取所有地址
        getAllMap() {
            this.$util.post('/address/allMap').then(res => {
                this.mapAll = res.data.all
            })
        },
        changePage(num) {
            this.needPage = num;
            this.allNeed()
        },
        goNeedPage() {
            this.needPage = this.needNum
            this.allNeed()
        },
        changeGoodsPage(num) {
            this.goodPage = num;
            this.allShop()
        },
        goGoodsPage() {
            this.goodPage = this.goodsNum
            this.allShop()
        },
        handleClick(tab) {
            this.current = tab.index
        },
        // 跳转查看需求
        goDemand(id) {
            this.$router.push({ path: '/demand', query: { id } })
        },
        goDetail(id, name) {
            this.$router.push({ path: '/shopDetail', query: { id, name } })
        },
    },
};
</script>

<style lang="scss" scoped>
.head {
    padding: 12px 0;
    background: #515151;
    .nav {
        width: 1200px;
        display: flex;
        margin: 0 auto;
        color: #fff;
        div {
            display: flex;
            cursor: pointer;
            align-items: center;
            padding-right: 53px;
            font-size: 14px;
        }
        .bor {
            border-right: 1px solid #707070;
            margin-right: 50px;
        }
    }
}
.user-box {
    padding: 70px 0 0;
    background: #666;
}
.user-info {
    width: 1200px;
    margin: 0 auto 0;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #fff;
    .logo {
        width: 120px;
        height: 120px;
        margin-right: 23px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
        }
    }
    .user-tags {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .tag {
            display: flex;
            align-items: center;
            margin-right: 40px;
            font-size: 16px;
        }
    }
}
.many {
    padding: 10px 0;
    background: rgba(255, 255, 255, 0.4);
    .nav {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        font-size: 22px;
        color: #222222;
        div {
            margin-right: 20px;
        }
        span {
            color: #2ea7e0;
        }
    }
}
.main {
    background: #f8f8f8;
    padding: 50px 0 80px;
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
    // width: 80px !important;
}
/deep/.el-tabs__item {
    font-size: 22px;
    color: #666666;
    width: 80px;
    text-align: center;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
// /deep/.el-tabs__nav-wrap::after {
//     display: none !important;
// }
.zan {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    margin: 20px 0 30px;
    img {
        width: 21px;
        height: 21px;
        margin-right: 10px;
    }
}
.main-box {
    width: 1200px;
    background: #fff;
    margin: 0 auto;
    padding: 50px 20px 0;
    // min-height: 1400px;
}
.need {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .need-cell {
        width: 520px;
        height: 174px;
        padding: 30px;
        border: 1px solid #e3e3e3;
        margin-bottom: 20px;
        cursor: pointer;
        .title {
            font-size: 24px;
            color: #222;
            margin-bottom: 20px;
        }
        .tag-box {
            display: flex;
            align-items: center;
            .tag {
                padding: 4px 12px;
                color: #222;
                font-size: 14px;
                background: #e3e3e3;
                margin-right: 10px;
                border-radius: 4px;
            }
        }
        .need-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #e40012;
            font-size: 24px;
            font-weight: bold;
            margin-top: 30px;
            .need-logo {
                display: flex;
                align-items: center;
                color: #222;
                font-size: 24px;
                img {
                    width: 67px;
                    height: 67px;
                    margin-right: 10px;
                }
            }
        }
    }
}
.litle-need {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .litle-cell {
        cursor: pointer;
        margin-right: 12px;
        background: #fffafa;
        width: 245px;
        padding: 30px 20px 30px;

        margin-bottom: 20px;
        border: 1px solid #fde1e2;
        .litle-title {
            font-size: 24px;
            color: #222;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .tag-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // flex-wrap: wrap;
            margin: 20px 0 24px;
            .tag {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 4px 8px;
                max-width: 60px;
                color: #222;
                font-size: 14px;
                background: #e3e3e3;
                margin-right: 10px;
                border-radius: 4px;
            }
        }
        .litle-money {
            font-size: 18px;
            color: #e40012;
        }
    }
}
.nice-shop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.good {
    width: 198px;
    padding: 20px;
    border: 1px solid #e3e3e3;
    img {
        width: 100%;
        height: 178px;
    }
    div {
        font-size: 14px;
        color: #222222;
        margin: 20px 0 10px;
    }
    span {
        color: #e40012;
        font-size: 14px;
    }
}
.shop {
    width: 280px;
    border: 1px solid #e3e3e3;
    margin-bottom: 15px;
    cursor: pointer;
}
.shop-info {
    padding: 15px;
    color: #222;
    font-size: 16px;
}
.shop-img {
    position: relative;
    height: 178px;
    // background: red;
    margin: 10px 0 20px;
    img {
        width: 100%;
        height: 100%;
    }
    div {
        width: 42px;
        color: #fff;
        height: 31px;
        line-height: 31px;
        font-size: 14px;
        text-align: center;
        background: #707070;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.shop-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 10px;
    div {
        padding: 7px 19px;
        background: #fde5e6;
        color: #e40012;
        margin-right: 10px;
        font-size: 12px;
        border-radius: 4px;
    }
}
.shop-num {
    padding: 20px 15px;
    background: #f8f8f8;
    color: #999999;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pagination {
    width: 1200px;
    margin: 30px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
/deep/.el-pager li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    border: 1px solid #2ea7e0;
    margin: 0 3px;
    color: #2ea7e0 !important;
}
/deep/.el-pagination .btn-next,
/deep/.el-pagination .btn-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px !important;
    // border: 1px solid #2ea7e0;
    background: transparent;
    margin: 0 3px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #fff !important;
}
/deep/.el-pagination .btn-next .el-icon,
/deep/.el-pagination .btn-prev .el-icon {
    font-size: 24px;
    color: #2ea7e0;
}
/deep/.el-pager li.active + li {
    border: 1px solid #2ea7e0;
}
.total {
    color: #2ea7e0;
    font-weight: bold;
    font-size: 16px;
}
.jump {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 0 20px 0 40px;
    color: #2ea7e0;
    input {
        width: 40px;
        height: 40px;
        border: 1px solid #0f6c97;
        font-size: 14px;
        color: #2ea7e0;
        outline: none;
        margin: 0 10px;
        border-radius: 4px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
</style>